.wrapper {
  height: 100vh;
  overflow: hidden;

  .content_box {
    width: 100%;
    padding-top: 64px;
    min-height: calc(100vh - 61px - 96px - 315px);
    padding-bottom: 100px;
    overflow: hidden;

    .content_title {
      width: fit-content;
      height: 50px;
      margin-bottom: 60px;

      h2 {
        font-size: 32px;
        color: $primary-purple;
        font-family: 'GTWalsheimPro-Bold';
        line-height: 1.38;
      }

      p {
        height: 5px;
        background: $primary-green;
      }
    }
  }

  .inner {
    width: 100%;
    max-width: 1440px;
    padding: 0 24px;
    margin: 0 auto;
  }

  .inner_max {
    width: 100%;
    max-width: 1360px;
    padding: 0 30px;
    margin: 0 auto;
  }
}

/** loading */
.client-portal-loading {
  display: inline-block;
  width: 150px;
  height: 153px;
  background: url($loading) no-repeat center;
  background-size: cover;
  animation: rotate 2s 1 linear;
  -moz-animation: rotate 2s infinite linear;
  -ms-animation: rotate 2s infinite linear;
  -webkit-animation: rotate 2s infinite linear;
  -o-animation: rotate 2s infinite linear;
}

@media (max-width: 768px) {
  .wrapper .content_box {
    padding-bottom: 48px;
    min-height: calc(100vh - 71px - 96px - 315px);
  }
}

@media (max-width: 550px) {
  .wrapper .content_box .content_title h2 {
    font-size: 24px;
    margin-bottom: 24px;
  }
}
