
              @import "@/assets/css/variables/variables.scss";
            

@import '@/assets/css/app.scss';
.v-leave {
  opacity: 1;
}
.v-leave-active {
  transition: opacity 0.5s;
}
.v-leave-to {
  opacity: 0;
}
.v-enter {
  opacity: 0;
}
.v-enter-active {
  transition: opacity 0.2s;
}
.v-enter-to {
  opacity: 1;
}

@import '../../assets/GTWalsheimPro/stylesheet.css';
