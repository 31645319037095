#menu,
.menu_shadow,
.menu_wrapper {
  transition: all 0.5s;
}

#menu {
  .menu_shadow {
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    position: fixed;
    @include rtl-sass-prop(left, right, 0);
    top: 0;
    z-index: 100;
  }

  .menu_wrapper {
    width: 230px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 200;
    background-color: $tertiary-purple !important;
    box-shadow: $box-shadow;

    .menu_logo,
    .menu_logo_small {
      height: 100px;
      text-align: center;
    }

    .menu_logo {
      .logoImg {
        background: url($logo2);
        padding: 37px 161px 0 0;
        background-size: 161px 37px;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-top: calc((64px - (50px * 0.6)) / 2);
      }

      .product {
        margin: 15px 35px 0;
        color: $primary-green;
        font-size: 16px;
        font-family: 'GTWalsheimPro-Medium';
        text-transform: uppercase;
        text-align: left;
      }
    }

    .menu_logo_small .logoImg {
      background: url(~@/assets/images/menu/menu_icon_home.png);
      padding: calc(32px * 0.7) calc(42px * 0.7) 0 0;
      background-size: calc(42px * 0.7) calc(32px * 0.7);
      background-repeat: no-repeat;
      cursor: pointer;
      margin-top: calc((64px - calc(32px * 0.7)) / 2);
    }

    /deep/ .el-menu-vertical-demo > div {
      .nav-title {
        padding-bottom: 10px;
        .nav-group__title {
          @include rtl-sass-prop(padding-left, padding-right, 20px);
          font-size: 12px;
          font-weight: 600;
          line-height: 1.92;
          color: $primary-green;
        }
      }

      &:nth-child(2) .nav-title {
        padding-top: 10px;
      }

      .el-menu-item .icon,
      .el-submenu .icon {
        margin: 0 10px 5px 15px;
        width: 20px;
      }

      .el-menu-item .icon_small,
      .el-submenu .icon_small {
        margin-left: 0;
      }
    }

    /** collapse status */
    .el-menu--collapse /deep/.el-submenu > .el-submenu__title {
      span {
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
        display: inline-block;
      }

      .el-submenu__icon-arrow {
        display: none;
      }
    }

    .el-scrollbar {
      .el-submenu .el-menu-item {
        padding: 0 20px !important;
        font-size: 11px;
      }
    }

    .el-menu-item span,
    .el-submenu span {
      font-size: 18px;
      display: inline-block;
      // width: 140px;
      overflow-x: hidden;
      white-space: initial;
      overflow-wrap: break-word;
      line-height: 20px;
      color: white;
    }

    .switch_to {
      font-size: 18px;
      border-radius: $button-radius;
      background-color: $primary-purple;
      line-height: 1;
      text-align: center;
      color: #ffffff;
      @include rtl-sass-value(padding, 17px 23.5px 16px 24.5px, 17px 24.5px 16px 23.5px);
      display: block;
      max-width: 183px;
      margin: 20px auto;
    }
  }
}

/deep/ .el-menu-item,
/deep/ .el-submenu__title {
  background-color: $quarternary-purple !important;
}

.divider {
  &:not(:nth-last-child(-n + 3)) {
    border-bottom: 0.1px solid hsla(0, 0%, 100%, 0.1);
  }
}

/* 激活状态 */
/deep/ .el-submenu.is-active > .el-submenu__title,
/deep/ .el-menu-item.is-active {
  background-color: $primary-purple !important;
}

.el-menu /deep/.el-submenu > .el-submenu__title .el-submenu__icon-arrow {
  @include rtl-sass-prop(right, left, 20px);
  @include rtl-sass-prop(left, right, unset);
}

/deep/ .el-menu--collapse .el-submenu.is-active .line,
/deep/ .el-menu-vertical-demo .el-menu-item.is-active .line {
  display: block;
}

// open sub menu.
/deep/.el-menu-vertical-demo .el-submenu.is-opened .el-submenu__title,
/deep/ .el-menu-vertical-demo .el-submenu.is-opened .el-menu-item {
  background-color: $secondary-purple !important;
}

/deep/ .el-menu-vertical-demo .el-submenu.is-opened .el-menu-item.is-active {
  background: $primary-purple !important;
}

.el-submenu .line,
.el-menu-vertical-demo .line {
  @include rtl-sass-prop(left, right, 0);
}

/deep/ .el-submenu .line,
/deep/ .el-menu-vertical-demo .line {
  width: 8px;
  height: 100%;
  position: absolute;
  @include rtl-sass-prop(left, right, 0);
  top: 0;
  display: none;
  background: $primary-purple;
}
/deep/ .el-menu-vertical-demo {
  margin-bottom: 100px;
  li[data-testid='menu.promotion'] {
    span::after {
      content: ' \25CF';
      font-size: 18px;
      color: #e91d2c;
    }
  }
}

/deep/ .el-scrollbar__view > ul {
  background-color: $tertiary-purple !important;
  border-right-width: 0px;
}

/deep/ .el-submenu .el-submenu__title i {
  font-family: 'GTWalsheimPro-Bold';
  color: white !important;
}

// open sub menu.
/deep/ .el-submenu.is-opened .el-submenu__title i {
  color: white !important;
}
